import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

const PT_BR_LOCALE = 'pt-br';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

export const formatDateByDayAbbreviatedMonthFullYear = (date) =>
  dayjs(date).locale(PT_BR_LOCALE).format('D [de] MMM [de] YYYY').toLowerCase();

export const formatDateByDayFullMonthYear = (date) =>
  dayjs(date)
    .locale(PT_BR_LOCALE)
    .format('D [de] MMMM [de] YYYY')
    .toLowerCase();

export const formatDateByDayFullMonthYearHourMinute = (date) =>
  dayjs(date)
    .locale(PT_BR_LOCALE)
    .format('D [de] MMMM [de] YYYY [às] H:mm')
    .toLowerCase();

export const formatDateByDayMonth = (date) => dayjs(date).format('DD/MM');

export const formatDateByDayMonthYear = (date) =>
  dayjs(date).format('DD/MM/YYYY');

export const formatDateByWeekdayDayMonth = (date) =>
  dayjs(date).locale(PT_BR_LOCALE).format('dddd, D [de] MMMM');

export const formatDateToMonthDayYear = (date) =>
  dayjs(date).format('MM/DD/YYYY');

export const getTimeTo = (date, { shortened } = { shortened: false }) => {
  dayjs.updateLocale(PT_BR_LOCALE, {
    relativeTime: {
      future: 'em %s',
      past: `%s ${shortened ? '' : 'atrás'}`,
      s: 'alguns segundos',
      m: 'um minuto',
      mm: '%d minutos',
      h: 'uma hora',
      hh: '%d horas',
      d: 'um dia',
      dd: '%d dias',
      M: 'um mês',
      MM: '%d meses',
      y: 'um ano',
      yy: '%d anos',
    },
  });

  return dayjs().locale(PT_BR_LOCALE).to(dayjs(date));
};

export const getShortenedTimeTo = (date) => {
  dayjs.updateLocale(PT_BR_LOCALE, {
    relativeTime: {
      future: 'em %s',
      past: '%s',
      s: 'há segundos',
      m: '1 min',
      mm: '%d min',
      h: '1 h',
      hh: '%d h',
      d: '1 d',
      dd: (num) => (num / 7 >= 1 ? `${Math.floor(num / 7)} sem` : `${num} d`),
      M: '1 mes',
      MM: '%d meses',
      y: '1 ano',
      yy: '%d anos',
    },
  });

  return dayjs().locale(PT_BR_LOCALE).to(dayjs(date));
};

export const subtractTimeFromNow = (date, { unit = 'day' } = {}) =>
  dayjs().diff(date, unit);
